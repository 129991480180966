.nav-link:hover {
    color:#000094;
    transition: 0.5s;
}

.nav-link-notactive {
    color: #36454f;
}

.bi-arrow-up-circle {
    color: #36454f;
    scale: -1;
}

.bi-arrow-up-circle:hover {
    color: #000094;
}

.border-bottom:hover {
    color: #000094;
}

.text-area-css {
    width: 70%;
    height: 180px;
}

.text-test-css {
    font-weight: 200;
    font-size: 13px;
}

.hidden {
    display: none;
}

.rotate {
    transform: rotate(90deg);
}

.text-bilan-show {
    width: 80%;
    height: 120px;
    font-size: 14px;
    color: rgb(115, 115, 115);
    resize: none;
    border-radius: 4px;
    border-color: rgb(246, 246, 246);
    background-color: rgb(246, 246, 246);
}

.sticky {
    position: fixed;
    top: 58px;
    left: 64px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 123px;
    margin-left: 47px;
    width: 100%;
    background-color: white;
    z-index: 1000;
  }