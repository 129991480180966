html {
    overflow-y: scroll;
}

.padding-top {
    padding-top: 10%;
    margin-left: 5%;
}
.container-css {
    margin-top: 2%;
    background-color: white;
    border-radius: 4px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: 100%;
  }

  .container-no-shadow {
    margin-top: 2%;
    background-color: white;
    border-radius: 4px;
    width: 100%;
  }

  .icon-marg {
    margin-left: 10%;
  }

  .data-txt-css {
    font-size: 14px;
    white-space: nowrap;
  }

  .badge-ok {
    background-color: #f6f6f6;
    color: black;
  }

  .badge-no {
    background-color: #f6f6f6;
    color: black;
  }

  .main-button:hover {
    background-color: #000094;
    color: white;
    border-color: white;
    white-space: nowrap;
    width: auto;
    border-radius: 4px;
    font-size: 15px;
    text-decoration: underline;
  }

  .main-button {
    background-color: #000094;
    color: white;
    border-color: white;
    white-space: nowrap;
    width: auto;
    border-radius: 4px;
    font-size: 15px;
  }

  .selector {
    border-radius: 4px;
    border-color: #C4C4C4;
  }
  
  .secondary-button {
    background-color: white;
    color: #000094;
    border-color: #000094;
    white-space: nowrap;
    width: auto;
    border-radius: 4px;
    font-size: 15px;
  }

  .secondary-button:hover {
    background-color: white;
    color: #000094;
    border-color: white;
    white-space: nowrap;
    width: auto;
    border-radius: 4px;
    font-size: 15px;
    text-decoration: underline;
  }

  .text {
    text-decoration: none;
  }

  .text:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .mark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgb(221, 221, 221);
  }