.analyticsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.block {
  flex: 1;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 3px 3px 5px #d6d6d6;
  margin-left: 20px;
  width: 100%;
}

.block:first-child {
  margin-left: 0;
}

.sub-block {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 10px;
}

.value {
  font-size: 32px;
  font-weight: 500;
  margin: 0;
}

.info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.info-title,
.info-value {
  margin: 0;
  font-size: 10px;
}

.info-value {
  margin-left: auto;
  color: gray;
}

.green {
  color: green;
}

.red {
  color: red;
}

.filter-options {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 14px;
  background: #F6F6F6;
  box-shadow: 0px 2px 4px 0px rgba(97, 97, 97, 0.20) inset, 0px 1px 2px 0px rgba(97, 97, 97, 0.20) inset;
}

.filter-option {
  margin-right: 10px;
  cursor: pointer;
  background-color: #F6F6F6;
  display: flex;
  padding: 4px 12px;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  border: none;
}

.more {
  border-radius: 4px;
  border: none;
  padding: 4px 12px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  text-decoration: none !important;
  color: black;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-option.selected {
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(115, 115, 115, 0.20);
}

.collab-select-form {
  display: inline-flex;
  padding: 7px 20px 7px 10px;
  align-items: center;
  border-radius: 4px;
  background: var(--logo-Bleu, #000094);
  color: var(--White, #FFF);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 175%;
  /* 28px */
  border: none;
  width: 300px;
}


.second_block {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-shadow: 3px 3px 5px #d6d6d6;
  position: relative;
}

.collab-select-form:hover {
  background: var(--logo-Bleu, #000094);
  color: var(--White, #FFF);
  text-decoration: none;
}

.collab-select-form:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 159, 227, 0.25);
}

.manager-component {
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding: 2%;
  border-radius: 10px;
  padding-left: 5%;
  box-shadow: 3px 3px 5px #d6d6d6;
  margin-bottom: 5%;
}

.manager-component {
  position: relative;
  padding-top: 100px;
}

.title-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 16px;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  padding-top: 10px;
}

.title-container .h5 {
  margin: 0;
  padding: 8px 0;
}

.manager-block {
  display: inline-block;
  margin-right: 40px;
  width: 16%;
  margin-bottom: 20px;
}

.manager-name {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 2px;
}

.collabs-text {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 2px;
}

.job {
  font-size: 10px;
}

.circle-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.circle {
  position: relative;
  width: 12vw;
  height: 12vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 10px;
}

.inner-circle {
  position: relative;
  z-index: 2;
  font-size: 1.2rem;
  font-weight: bold;
}

.circle::before {
  content: '';
  position: absolute;
  top: 10px; /* Adjusted for thickness */
  left: 10px; /* Adjusted for thickness */
  width: calc(100% - 20px); /* Adjusted for thickness */
  height: calc(100% - 20px); /* Adjusted for thickness */
  border-radius: 50%;
  background-color: white;
  z-index: 1;
}

.circle::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(
    rgba(0, 0, 148, 1) calc(var(--percentage) * 1%),
    #f6f6f6 0
  );
  z-index: 0;
  padding: 10px; /* Adjusted for thickness */
  box-sizing: border-box;
}

.circle.striped::before {
  content: '';
  position: absolute;
  top: 10px; /* Ajusté pour l'épaisseur */
  left: 10px; /* Ajusté pour l'épaisseur */
  width: calc(100% - 20px); /* Ajusté pour l'épaisseur */
  height: calc(100% - 20px); /* Ajusté pour l'épaisseur */
  border-radius: 50%;
  z-index: 1;
}

.circle.striped::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: 
    conic-gradient(
      #ffffff25 calc(var(--percentage) * 1%),
      #f6f6f6 0
    ),
    linear-gradient(-45deg, transparent 45%, #0053FF 45%, #0053FF 55%, transparent 55%) 0 0/10px 10px,
    linear-gradient(-45deg, transparent 45%, #0053FF 45%, #0053FF 55%, transparent 55%) 5px 0px/10px 10px;
  background-blend-mode: normal;
  z-index: 0;
  padding: 10px; /* Ajusté pour l'épaisseur */
  box-sizing: border-box;
}



.hardis {
  border: 8px solid #00009456;
}

.mission {
  border: 8px solid #22222225;
}

.chart-container {
  display: flex;
  box-shadow: 3px 3px 5px #d6d6d6;
  margin-bottom: 5%;
}

.collab-bar-chart {
  width: 50%;
  margin: 4% 2% 4% 2%;
}

.collab-bar-chart-practice {
  margin: 4% 2% 4% 2%;
}

.chart-container-practice {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Espace entre les graphiques */
  width: 100%;
}

.chart-item-practice {
  min-height: 400px; /* Définissez une hauteur minimale pour éviter que les graphiques ne deviennent trop petits */
  height: auto;
}

.icon-container {
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 10px;
  font-weight: bold;
  display: contents;
}


.icon-line {
  display: flex;
  margin-top: 10%;
  align-items: center;
  justify-content: space-evenly;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 58px;
  left: 64px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 123px;
  margin-left: 47px;
  width: 100%;
  background-color: white;
  z-index: 1000;
}

.legend {
  display: flex;
  align-items: center;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.legend-color {
  width: 42px;
  height: 14px;
  margin-right: 8px;
}

.country-button {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.analytics-bloc-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 0 20px;
  height: 50vh;
}

.analytics-block-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  margin-bottom: 20px;
  height: 45vh;
}

.analytics-block-wrapper:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .analytics-bloc-container {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .analytics-block-wrapper {
    width: 100%;
    margin-bottom: 10px;
    height: 40vh; /* Adjust height for smaller screens */
  }
}
