.subtitle-profile {
  font-size: 12px;
  color: grey;
  margin-left: -35%;
  margin-top: -5%;
}

.img-rounded {
  max-width: 30%;
  border-radius: 50%;
}

.add-btn:hover {
  background-color: white;
  color: #000094;
  border-color: #000094;
  /* width: 120%; */
}

.add-btn {
  background-color: #000094;
  color: white;
  border-color: white;
  transition: 0.5s;
}

.cancel-btn {
  background-color: white;
  color: red;
  border-color: red;
  /* width: 120%;
    margin-left: 60%; */
}
.cancel-btn:hover {
  background-color: red;
  color: white;
  border-color: white;
  transition: 0.5s;
}

.Namecss {
  font-weight: 500;
  font-size: 18px;
  margin-top: 5%;
  margin-left: -35%;
}

.btn {
  width: 120px;
}

.rattachement-data {
  font-size: 14px;
  margin-left: -55%;
  margin-top: -2%;
}

.rattachement-subtitle {
  margin-left: -55%;
  margin-top: 5%;
}

.rattachement-subtitle-note {
  margin-top: 5%;
}

.rattachement-note {
  font-size: 14px;
  margin-top: -2%;
}

.container-css {
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}