.nav-link:hover {
  border-bottom: #000094;
  color: #000094;
  font-weight: 500;
}

.nav-link:active {
  border-bottom: #000094;
  color: #000094;
  font-weight: 500;
}
.nav-link {
  color: #000094;
  font-weight: 500;
}

.border-primary {
  border-bottom: #000094;
}

.secure {
  margin-right: 10%;
}

.Padding-top {
  padding-top: 10%;
}

.add-btn:hover {
  background-color: white;
  color: #000094;
  border-color: #000094;
  /* width: 120%; */
}

.add-btn {
  background-color: #000094;
  color: white;
  border-color: white;
  transition: 0.5s;
}

.cancel-btn {
  background-color: white;
  color: red;
  border-color: red;
  /* width: 120%; */
}
.cancel-btn:hover {
  background-color: red;
  color: white;
  border-color: white;
  transition: 0.5s;
}
