.test-ver {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.bi {
  color: #36454f;
}
.bi:hover {
  color: #000094;
  transition: 0.5s;
}

.img-logo {
  width: 100px;
  margin-top: 100px;
  margin-bottom: 50%;
}

.secure {
  /* background-color: white; */
  width: 10%;
  margin-right: 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  padding-top: 70px;
}

@media (max-width: 700px) {
  .test-ver {
    display: none;
  }
}
