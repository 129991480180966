html {
    overflow-y: scroll;
}

.column-title {
    white-space: nowrap;
    color: rgb(115, 115, 115);
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.center-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    font-size: 16px;
    margin: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.column-button {
    border-color: white;
    background-color: white;
    border: none;
    outline: none;
}

.column-button:hover {
    background-color: #F6F6F6;
    border-color: #F6F6F6;
    border: none;
    outline: none;
}