.nav-link:hover {
    color:#000094;
    transition: 0.5s;
}

.nav-link-notactive {
    color: #36454f;
}


.nav-link-active {
    color: #36454f;
    font-weight: bold;
  }

.border-bottom:hover {
    color: #000094;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: #000094 !important;
}

.title-component {
    font-size: 10 px;
    font-weight: bold;
    color: #333;
    line-height: 2;
  }
  
.img-profile-logo {
    max-width: 7%;
    border-radius: 70%;
    line-height: 2;
    /* border: 4px solid rgb(12, 178, 255); */
  }

  
  .container-info {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transform: translate(-18%, -1%);
    line-height: 2;
    border-radius: 4px;
    padding: 5% 0px 5% 5%;
    top: 50%;
    left: 50%;
    width: 170%;
    height: 95%;
    justify-content: center;
    align-items: center;
  }

  .collab-form {
    width: 80%;
    height: 5%;
    font-size: 14px;
    color: rgb(115, 115, 115);
    resize: none;
    border-radius: 4px;
    border-color: rgb(246, 246, 246);
    background-color: rgb(246, 246, 246);
  }

  .collab-date {
    width: 60%;
    height: 5%;
    font-size: 14px;
    color: rgb(115, 115, 115);
    resize: none;
    border-radius: 4px;
    border-color: rgb(246, 246, 246);
    background-color: rgb(246, 246, 246);
  }

  .practice-checkboxes-view {
    padding-left: 25px;
    padding-right: 10px;
    border-radius: 4px;
    width: 80%;
    background-color: #F6F6F6;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    color: rgb(115, 115, 115);
  }

  .practice-checkboxes-view .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .practice-checkboxes-view .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .form-check-input:checked.practice-checkbox-readonly {
    background-color: #737373;
    border-color: #737373;
  }
  