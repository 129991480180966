.popup-container {
  position: fixed;
  top: -10%;
  left: -7%;
  transform: translate(-50%, -50%);
}

.modal-content-bis {
  z-index: 9998;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-40%, -50%);
  line-height: 1.5;
  background: white;
  padding: 2% 2%;
  border-radius: 4px;
  width: 67%;
  height: auto;
  max-height: 80%;
}

.modal-bis {
  z-index: 9997;
  display: block;
  justify-content: center;
}

.modal-content-bis .popup-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}


.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 120%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.number-input {
  width: 90%;
  font-size: 14px;
  resize: none;
  border-radius: 4px;
  border-color: rgb(196, 196, 196);
}

.date-input {
  width: 80%;
  height: 5%;
  font-size: 14px;
  resize: none;
  border-radius: 4px;
  border-color: rgb(196, 196, 196);
}

/* background color bleu quand une option avec une classe practice-option est selectionnée */
.practice-option:checked {
  background-color: #000094;
  color: white;
}

.form-check-input:checked {
  background-color: #000094;
  border-color: #000094;
}

.practice-checkboxes .row {
  display: flex;
  flex-wrap: wrap;
}

.practice-checkboxes .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}