body {
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 150px; */
  padding-bottom: 40px;
  /* background-color: #f8f6f6; */
}
.form-signin {
  max-width: 600px;
  padding: 15px;
}

.form-signin input[type="email"] {
  width: 400px;
  margin-bottom: 10px;
  border-radius: 2;
}

.form-signin input[type="password"] {
  width: 400px;
  margin-bottom: 10px;
  border-radius: 2;
}

.btn {
  width: 400px;
}
.btn-color {
  margin-top: 3%;
  border-radius: 4px;
  /* justify-content: center; */
  /* align-items: center; */
  background-color: #000094;
  color: white;
}

.btn-color:hover {
  background-color: white;
  color: #000094;
  border-color: #000094;
  transition: 0.5s;
}

.p-error {
  font-weight: 500;
  width: 400px;
  font-size: 12px;
  margin-top: 3%;
  color: red;
}

.anchor-link {
  font-weight:600;
  color: #000094;
  text-decoration: none;
}

.custom-error {

}

.img-home-logo {
  width: 145px;
}
