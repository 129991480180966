.sub-title {
    font-size:12px;
    color: grey;
}


.select-title {
    font-size: 14;
}

.ms-plus {
    margin-left: 8.3%;
}

.number-input {
    font-size: 14px;
    resize: none;
    border-radius: 4px;
    border-color: rgb(196, 196, 196);
}