body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.modal {
  z-index: 9997;
  display: block;
}

.overlay {
  z-index: 9996;
  background: rgba(00,00,00,0.4);
}
.modal-content {
  z-index: 9998;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.5;
  background: white;
  padding: 2% 2%;
  border-radius: 4px;
  width: 67%;
  height: auto;
  max-height: 85%;
  overflow: auto;
  margin-top: 30px;
}

.custom-div {
  margin-top: -2%;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.custom-selector {
  border-radius: 4px;
  width: 33%;
  border-color: #C4C4C4;
  
}

.option {
  font-size: 14px;
}

.option:hover {
  background-color: #C4C4C4;
  color: black;
}

.input-modal {
  font-size: 14px;
  resize: none;
  border-radius: 4px;
  border-color: rgb(196, 196, 196);
}

/* background color bleu quand une option avec une classe practice-option est selectionnée */
.practice-option:checked {
  background-color: #007bff;
  color: white;
}

.form-check-input:checked {
  background-color: #000094;
  border-color: #000094;
}

.practice-checkboxes .row {
  display: flex;
  flex-wrap: wrap;
}

.practice-checkboxes .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
