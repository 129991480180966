.maintenance {
    height: 100%;
    display: block;
    padding-bottom: 0;
    color: #818181;
    font-family: "Raleway", sans-serif;
    text-align: center;
  }
  
  .maintenance__container {
    padding: 30px;
    margin: 0 auto;
    max-width: 600px;
  }
  
  .maintenance__container__content {
    margin-top: 50px;
  }
  
  .maintenance__container__content__logo {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
  
  .maintenance__container__content__title {
    font-size: 30px;
  }
  
  .maintenance__container__content__text {
    font-size: 20px;
  }
  